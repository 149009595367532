<template>
    <div class="content-wrapper">
    <validation-observer
        v-slot="{ handleSubmit }" ref="VForm"
    >
        <b-form @submit.prevent="handleSubmit(doSubmit)">

        <div class="page-header page-header-sticky page-header-sticky-open">
            <div class="page-header-content page-header-dark mb-0 header-elements-md-inline">

                <div class="page-header-info flex-1 py-3 text-uppercase">
                    <div class="d-flex justify-content-between">
                        <div>
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="phi-main font-weight-semibold mb-2 pb-1">
                                    <span>{{row.ap_fullname||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No Rekam Medis">{{row.ap_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left">{{rowReg.aranr_reg_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No KTP">{{row.ap_nik||"-"}}</span>
                                </h5>
                            </div>
                            <div class="phi-sub-info d-flex">
                                <div class="phi-info d-flex flex-column">
                                    <small>Tanggal Lahir</small>
                                    <strong class="font-weight-semibold">{{row.ap_dob | moment("DD MMM YYYY")}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Usia</small>
                                    <strong class="font-weight-semibold">{{row.ap_usia_with_ket || "-"}}<br />
                                    ({{row.ap_gol_usia||"-"}})</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Jenis Kelamin</small>
                                    <strong class="font-weight-semibold">{{row.cg_label}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Opsi Pembayaran</small>
                                    <strong class="font-weight-semibold">{{rowReg.mcp_name || "-"}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Agama</small>
                                    <strong class="font-weight-semibold">{{row.ap_agama_text || "-"}}</strong>
                                </div>
                            </div>
                        </div>
                        <div class="phi-info d-flex flex-column ml-auto">
                            <small>Dokter</small>
                            <h5 class="phi-main font-weight-semibold mb-0">
                                <span>{{rowReg.bu_full_name||"-"}}</span>
                            </h5>
                            <div class="mb-2">
                                <strong class="font-weight-semibold">{{rowReg.mpo_name||"-"}}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a href="javascript:;" class="btn btn-sm btn-primary page-header-sticky-toggle">
            <i class="icon-arrow-up5 align-middle"></i>
            <span class="align-middle">Sembunyikan Informasi Pasien</span>
            </a>
        </div>
        <div class="content">
            <div class="card">
            <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Tambah Form HAIs</h6>
            </div>
            <div class="card-body p-3" v-for="(v,k) in (dataHais||[])" :key="k">
                <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                            <label>Pilih Kategori {{k+1}}<small class="txt_mandatory">*</small></label>
                            <b-form-group>
                                <v-select placeholder=" -- Pilih Kategori -- " v-model="dataHais[k].aranha_kategori" :options="getMasterHais(mHais,dataHais,k)" @input="selectComponent($event,k)" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                            </b-form-group>
                            <VValidate 
                                :name="`Kategori ${k+1}`" 
                                v-model="dataHais[k].aranha_kategori" 
                                :rules="toValidate(mrValidation.aranha_kategori)"
                            />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="" for="">Tanggal</label>
                                <div class="input-group">
                                <datepicker v-model="dataHais[k].aranha_date" input-class="form-control transparent" placeholder="Pilih Tanggal" class="my-datepicker" calendar-class="my-datepicker_calendar">
                                </datepicker>
                                <div class="input-group-append calendar-group">
                                    <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                </div>
                                </div>
                                <VValidate :name="'Tanggal #'+(k+1)" v-model="dataHais[k].aranha_date" :rules="{required: 1}" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="" for="">Jam</label>
                                <div class="input-group">
                                <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                                <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="dataHais[k].aranha_jam">
                                </vue-timepicker>
                                </div>
                                <VValidate :name="'Jam #'+(k+1)" v-model="dataHais[k].aranha_jam" :rules="{required: 1}" />
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="v.aranha_kategori">
                        <div class="col-md-6">
                            <div class="wrap_line">
                                <b-form-group label="Komponen">
                                    <b-form-checkbox-group
                                        v-model="dataHais[k]['aranha_komponen']"
                                        :options="dataHais[k]['aranha_c_komponen']"
                                        :name="'component'+k"
                                        value-field='name'
                                        text-field='name'
                                        class="checkbox-block mt-2"
                                    ></b-form-checkbox-group>
                                </b-form-group>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="wrap_line">
                            <h3> <span>Kejadian </span></h3>

                            <div class="form-row">
                                <div class="col-md-12">
                                <div class="form-group">
                                    <div class="form-row mb-2" v-for="(v1,k1) in dataHais[k]['aranha_kejadian']" :key="k1">
                                        <div class="col-md-6">
                                            <div class="form-check form-check-inline">
                                            <label class="form-check-label">
                                                <b-form-checkbox v-model="dataHais[k]['aranha_kejadian'][k1]['value']" class="form-check-input-styled" name="radio-inline-left"
                                                data-fouc />
                                                {{v1.name}}
                                            </label>
                                            </div>
                                        </div>
                                        <div class="col-md-6" v-if="dataHais[k]['aranha_kejadian'][k1]['value']">
                                            <b-form-input type="text" v-model="dataHais[k]['aranha_kejadian'][k1]['val_name']" name="name" class="form-control" placeholder="Catatan"/>
                                        </div>
                                    </div>
                                </div>


                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                
                <div class="row">
                    <div class="col-md-12">
                        <div class="text-right mr-3 mb-3">
                        <button type="button" class="btn btn-light mr-1" v-if="(dataHais||[]).length > 1" @click="deleteHais(k,v)">Hapus</button>
                        <button type="button" class="btn btn-light" @click="addNew">Tambah</button>
                        </div>
                    </div>
                </div>
            </div>
            

            <div class="card-footer">
                <div class="text-right">
                <button @click="$router.back()" class="btn btn-light mr-1">Back</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                </div>
            </div>
            </div>
        </div>

        </b-form>
    </validation-observer>
    </div>
</template>

<script>
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default{
    components: {Datepicker, VueTimepicker},
    props:{
        row:Object,
        rowReg:Object,
        mrValidation:Object,
        Config: Object,
        dataHais: Array,
        mHais: Array,
        riwayatHais: Array
    },
    
    data(){
        return {
            deletedData : []
        }
    },
    mounted() {    
        setTimeout(()=>{
            this.initSticky()
        },1000)
    },
    methods: {
        initSticky(){
            const PageHeaderSticky = document.querySelector('.page-header-sticky')
            if(PageHeaderSticky){    
                const PageHeaderToggle = PageHeaderSticky.querySelector('.page-header-sticky-toggle')
                if($(".page-header-sticky").length && $(".page-header-sticky-toggle").length){
                    PageHeaderToggle.addEventListener('click', function(e){
                    // e.preventDefault()
                    PageHeaderSticky.classList.toggle('page-header-sticky-open')
                    if (PageHeaderSticky.classList.contains('page-header-sticky-open')) {
                        PageHeaderToggle.querySelector('span').innerText = 'Sembunyikan Informasi Pasien'
                        PageHeaderToggle.querySelector('i').classList.add('icon-arrow-up5')
                        PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-down5')
                    }else{
                        PageHeaderToggle.querySelector('span').innerText = 'Tampilkan Informasi Pasien'
                        PageHeaderToggle.querySelector('i').classList.add('icon-arrow-down5')
                        PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-up5')
                    }
                    })    
                } 
            }
        },
        addNew(){
            this.dataHais.push({
                aranha_aranr_id : this.$parent.pageSlug,
                aranha_kategori : null, 
                aranha_komponen : [],
                aranha_kejadian : [],
                aranha_date: moment().format('YYYY-MM-DD'),
                aranha_jam : moment().format('HH:mm'),
                aranha_id : null   
            })
        },
        deleteHais(k,v){
            if(v.aranha_id){
                this.deletedData.push(v.aranha_id)
            }
            this.dataHais.splice(k,1)
        },
        doSubmit(){
            this.$refs['VForm'].validate().then(success=>{
                if(success){
                    this.$swal({
                        icon: 'warning',
                        title: 'Apakah Anda Yakin akan menyimpan data ini?',
                        showCancelButton: true,
                        confirmButtonText: 'Ya',
                        cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.row
                            data.dataHais = this.dataHais
                            data.deletedData = this.deletedData
                            data.type = 'add-hais'

                            this.$parent.loadingOverlay = true
                            Gen.apiRest(
                                "/do/"+this.$parent.modulePage,
                                {data:data}, 
                                "POST"
                            ).then(res=>{
                                this.$parent.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok'
                                }).then(result => {
                                    if (result.value) {
                                        this.$router.push({name:this.$route.name}).catch(()=>{})
                                    }
                                })
                            }).catch(err=>{
                                this.$parent.loadingOverlay = false
                                if(err){
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.$parent.doSetAlertForm(err)
                            })
                            
                            // 934?regId=1367
                        }
                    })
                }else{
                    setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                            if(el[i].style.display !== 'none'){
                                inv.push(el[i].id)
                            }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                }
            })
        },
        toValidate(val){
            return {...val}
        },
        
        selectComponent(e,k){
            let idx = this.mHais.findIndex(x => x.value == e)
            if(idx !== -1){
                this.dataHais[k].aranha_c_kejadian = this.mHais[idx]['mh_kejadian']
                this.dataHais[k].aranha_kejadian = this.mHais[idx]['mh_kejadian']

                this.dataHais[k].aranha_c_komponen = this.mHais[idx]['mh_component']
            }else{
                this.dataHais[k].aranha_c_kejadian = this.mHais[idx]['mh_kejadian']
                this.dataHais[k].aranha_kejadian = []
                
                this.dataHais[k].aranha_c_komponen = []
            }
        },
        
        getMasterHais(master,dataHais,k){
            const sldMenu = _.map(_.filter(dataHais||[], (v2, k2)=>{
                return k2!=k
            }), v2=>{
                return v2.aranha_kategori
            })
            return _.filter(master, v2=>{
                return sldMenu.indexOf(v2.value)<=-1
            })
        },
    }
}
</script>
